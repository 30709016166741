import * as React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const CurrentlyRunning = (props) => {
  const [isOpen, setIsOpen] = React.useState([]);
  const { currentlyRunning, products, collections } = props;

  // if (!currentlyRunning) { currentlyRunning = [] }

  // for (var i = 0; i < 500; i++) {
  //   currentlyRunning.push({ type: "Products", type_id: i % 3, roster_id: i % 100 });
  // }
  // console.log(currentlyRunning)

  const convertIds = (run, productCollectionDict) => {
    const mapping =
      productCollectionDict[run.roster_id.toString() + run.type_id.toString()];
    if (mapping) {
      run.full_name = mapping.full_name;
      run.sku_name = mapping.sku_name;
    }
    return run;
  };

  //console.log("currently running", currentlyRunning, products, collections);


  const productCollectionDict = {};
  collections?.forEach((c) => {
    productCollectionDict[c.RosterId.toString() + c.CollectionId.toString()] = {
      full_name: c.FullName,
      sku_name: "",
    };
  });

  products?.forEach((p) => {
    productCollectionDict[p.RosterId.toString() + p.ProductId.toString()] = {
      full_name: p.FullName,
      sku_name: ":" + p.SkuName,
    };
  });

  const grouped = {};
  currentlyRunning.forEach((run) => {
    if (grouped[run.type]) {
      // do nothing
    } else {
      grouped[run.type] = [];
    }

    grouped[run.type].push(convertIds(run, productCollectionDict));
  });

  if (Object.keys(grouped).length === 0) {
    return (
      <Box
        sx={{
          width: "fit-content",
          margin: "auto",
          backgroundColor: "background.paper",
          marginBottom: "10px",
        }}
      >
        <Typography
          style={{ margin: "auto", width: "fit-content", padding: "5px" }}
        >
          Nothing running
        </Typography>
      </Box>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        borderRadius: "4px",
        paddingBottom: "10px",
      }}
    >
      {Object.keys(grouped).map((type, i) => (
        <>
          <Typography fontSize={"20px"} sx={{ width: "100%" }}>
            {`Running ${cap(type)} (${grouped[type].length})`}
          </Typography>
          <Box
            key={type}
            sx={{
              backgroundColor: "background.paper",
              padding: "15px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              height: isOpen[i] ? "auto" : "70px",
              overflow: "hidden",
            }}
          >
            <StatusBar data={grouped[type]} />
          </Box>
        </>
      ))}
    </div>
  );
};

const generateColorFromId = (roster_id) => {
  const hue = (roster_id * 137) % 360; // 137 is a prime number to spread out hues
  const saturation = 70 + (roster_id % 30); // Saturation between 70-100%
  const lightness = 30 + (roster_id % 20);  // Lightness between 50-70%
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

const StatusBar = ({ data }) => {
  // Sort data by roster_id to group like-colored blocks together
  const sortedData = [...data].sort((a, b) => a.roster_id - b.roster_id);
  const blockWidth = `${100 / sortedData.length}%`; // Calculate width for each block

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {sortedData.map(({ roster_id, type_id }, index) => (
        <div
          key={`${roster_id}-${type_id}-${index}`} // Unique key for each block
          style={{
            backgroundColor: generateColorFromId(roster_id),
            width: blockWidth,
            height: '30px',
          }}
          title={`Roster: ${roster_id}, Type: ${type_id}`} // Tooltip info
        ></div>
      ))}
    </div>
  );
};

const cap = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export default CurrentlyRunning;
